import './App.css';
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import Home from './pages/Home';
import ItemShow from './pages/ItemShow';
import Checkout from './pages/Checkout';
import ShoppingCart from './pages/ShoppingCart';
import NotFound from './pages/NotFound';
import Layout from './pages/Layout';

import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import { HunelProvider, HunelCreditCard } from 'reactjs-credit-card';

const hunel = new HunelCreditCard({
  middlePartHide: false,
  yearLength: 10, //from 2022 to 2036
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
if(process.env.NODE_ENV  === 'production') hotjar.initialize(2484890, 6);

function App() {
  return (
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor} >
        <BrowserRouter>
          <Layout>
            <HunelProvider config={hunel}>
              <Switch>
                <Route exact path="/:shopId/checkout" component={Checkout} />
                <Route exact path="/:shopId" component={Home} />
                <Route exact path="/:shopId/item/:itemId" component={ItemShow} />
                <Route exact path="/:shopId/cart" component={ShoppingCart} />
                <Route component={NotFound} />
              </Switch>
            </HunelProvider>
          </Layout>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
