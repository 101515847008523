import React, { Component } from 'react';
import { FaShoppingCart } from "react-icons/fa";

export default class EmptyCart extends Component {
  render() {
    return (
      <div className="text-center" style={{marginTop: '6rem', marginBottom: '3rem', fontSize: '0.8rem'}}>
        <FaShoppingCart size={35} />
        <p className="py-3"><strong>Tu pedido está vacío</strong> <br/>
        Al parecer no has agregado nada a tu pedido.</p>
      </div>
    );
  }
}
