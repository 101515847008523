import React from 'react';

class CategoryListItem extends React.Component {
    render() {
      return (
        <div className={`GridItem_card menu-item ${this.props.selected ? 'active' : ''}`}>
          <div className="py-2 text-center Font-overline GridItemCategory_caption" style={{fontSize: '0.675rem'}}>
            {/* <img src="https://cloudbizshop.s3.amazonaws.com/item_images/cbzshop_6088745b1eceb32ecec1f79f_1619629947076.jpg" className="rounded" alt="item_image"></img> */}
            {this.props.item.name}
          </div>
        </div>
      );
    }
}

export default CategoryListItem;