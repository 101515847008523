const initState = {
    cartItems:[],
    indexCount: 0,
    itemsCount: 0,
    total: 0,
    currency: {},
    categorySelected: null
}

function data(state = initState, action) {
    switch (action.type) {
        case 'SET_ACCOUNT': {
            return {...state, accountShop: action.payload.accountShop }
        }
        case 'SET_PRODUCT_LIST': {
            return {...state, ...action.payload}
        }
        case 'SET_CATEGORY_LIST': {
            return {...state, ...action.payload}
        }
        case 'SET_CURRENCY': {
            return{...state, currency: action.payload.currency}
        }
        case 'SET_SELECTED_CATEGORY': {
            return{...state, categorySelected: action.id}
        }
        case 'ADD_TO_CART' : {
            let existed_item = state.cartItems.find(item=> item.id === action.item.id);

            if(existed_item && !existed_item.variants){
                existed_item.quantity += 1;
                state.itemsCount += 1;

                return{
                    ...state,
                    cartItems: state.cartItems.map(
                        (content, i) => content.id === action.item.id ? {
                            ...content,
                            variants: action.item.variants } : content
                    ),
                    total: state.total + existed_item.price
                }
            } else{
                //calculating the total
                let newTotal = state.total + action.item.price 
                state.itemsCount += 1;
                state.indexCount += 1;

                return{
                    ...state,
                    cartItems: [...state.cartItems, action.item],
                    total : newTotal
                }
            }
        }
        case 'REMOVE_FROM_CART' : {
            let itemToRemove= state.cartItems.find(item => action.idCart === item.idCart);
            let new_items = state.cartItems.filter(item => action.idCart !== item.idCart);
            state.itemsCount -= itemToRemove.quantity;
            //calculating the total
            let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity);
            return{
                ...state,
                cartItems: new_items,
                total: newTotal
            }
        }
        case 'ADD_QUANTITY' : {
            let addedItem = state.cartItems.find(item=> item.idCart === action.idCart);

            if(addedItem){
                let newTotal = state.total + addedItem.price
                state.itemsCount += 1;

                return {...state, 
                    cartItems: state.cartItems.map(
                        (content, i) => content.idCart === action.idCart ? {
                            ...content,
                            quantity: content.quantity += 1 } : content
                    ),
                    total: newTotal
                }
            }
        }
        break;
        case 'SUB_QUANTITY' : {
            let addedItem = state.cartItems.find(item=> item.idCart === action.idCart);
            let newTotal = state.total - addedItem.price

            //if the qt == 0 then it should be removed
            if(addedItem && addedItem.quantity === 1){
                let new_items = state.cartItems.filter(item=>item.idCart !== action.idCart);
                state.itemsCount -= 1;

                return{
                    ...state,
                    cartItems: new_items,
                    total: newTotal
                }
            }else {
                state.itemsCount -= 1;

                return{
                    ...state,
                    cartItems: state.cartItems.map(
                        (content, i) => content.idCart === action.idCart ? {
                            ...content,
                            quantity: content.quantity -= 1 } : content
                    ),
                    total: newTotal
                }
            }
        }
        case 'EMPTY_CART' : {
            return{
                ...state,
                cartItems: [],
                indexCount: 0,
                itemsCount: 0,
                total: 0
            }
        }
        default:
            return state
    }
}

export default data;