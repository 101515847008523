import React, { Component } from 'react';
import { FaTrashAlt, FaMinus, FaPlus } from "react-icons/fa";
import NumberFormat from './number-format';

// Redux
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
    return{
      currency: state.currency,
      total: state.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeItem: (id) => dispatch({
            type: 'REMOVE_FROM_CART',
            idCart: id
        }),
        addQuantity: (id) => dispatch({
            type: 'ADD_QUANTITY',
            idCart: id
        }),
        subtractQuantity: (id) => dispatch({
            type: 'SUB_QUANTITY',
            idCart: id
        })
    }
}

class CartListItem extends Component {
    deleteItem = async (item) => {
        try {
            this.props.removeItem(item.id);
        } catch (error) {
            console.log(error);
            //this.setState({ loading: false, error: error });
        }
      }

    render() {
        return (
            <div className="Card-root Card-elevation Card-rounded Cart_card">
                <div className="Grid-spacing-xs-1 Grid-wrap-xs-nowrap Grid-container Cart_productContainer">
                    <div style={{display: 'flex', padding: '16px'}}>
                        <div className="Cart_productImage" style={{margin: 'auto'}}>
                            {( this.props.item.defaultImage !== null )
                                ? <img key={this.props.item.id} style={{width: "100%"}} src={this.props.item.defaultImage} alt={this.props.item.itemName} />
                                : <img src="/image-placeholder.png" width="100%" alt="item_image" />
                            }
                        </div>
                        <div className="Cart_productDetailsWrapper">
                            <div>
                                <h6 style={{color: '#737373'}} className="mb-1">{this.props.item.itemName}</h6>
                                <p className="Cart_productDetailFont" >
                                    <NumberFormat
                                    typeText={'small'}
                                    number={this.props.item.price}
                                    prefix={this.props.currency.symbol} />
                                    <small>{' ('+this.props.item.quantity+')'}</small>
                                </p>

                                <p className="Cart_productDetailFont" style={{fontWeight: '600'}}>
                                    <NumberFormat
                                    typeText={'small'}
                                    fontWeight={'500'}
                                    number={(this.props.item.price * this.props.item.quantity)}
                                    prefix={this.props.currency.symbol} />
                                </p>

                                <div className="pt-2">
                                    <button
                                    onClick={()=> this.props.subtractQuantity(this.props.item.idCart)}
                                    type="button"
                                    className="btn btn-light btn-sm Cart_quantityButton mx-2">
                                        <FaMinus size={10} />
                                    </button>
                                    <small className="px-2">{this.props.item.quantity}</small>
                                    <button
                                    onClick={()=> this.props.addQuantity(this.props.item.idCart)}
                                    type="button"
                                    className="btn btn-light btn-sm Cart_quantityButton mx-2">
                                        <FaPlus size={10} />
                                    </button>
                                </div>
                                { this.props.item.variants &&
                                    <div className="pt-2">
                                        {this.props.item.variants.map(element => {
                                            let [value] = Object.entries(element);
                                            return (
                                                <p key={value[0]} className="Cart_productDetailFont" >
                                                    <small>{value[0]}: {value[1][0]}</small>
                                                </p>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                    
                            <button
                            onClick={() => this.props.removeItem(this.props.item.idCart)}
                            type="button"
                            className="btn btn-outline-danger btn-sm Cart_removeProduct">
                                <FaTrashAlt size={12} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CartListItem);