import React, { Component } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import '../css/Checkout.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json'
import Spinner from 'react-bootstrap/Spinner';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import PayGateForm from './payGateForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from './number-format';

import api from '../api';
import mixpanelApi from '../mixPanel';

// Redux
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
  return{
    cartItems: state.cartItems,
    total: state.total,
    itemsCount: state.itemsCount,
    currency: state.currency,
    accountShop: state.accountShop
  }
}

function mapDispatchToProps(dispatch) {
  return {
    emptyCart: () => dispatch({
      type: 'EMPTY_CART'
    })
  }
}

class CheckoutForm extends Component {
  date = new Date();
  year = this.date.getFullYear();
  month = this.date.getMonth() + 1;
  state = {
    loading: false,
    error: null,
    form: {
      name: '',
      mobile_number: '',
      shippingAddress: '',
      notes: ''
    },
    paymentData: {
      firstName: '',
      lastName: '',
      validThru: '',
      paymentType: '',
      safeIdentifier: '',
      cvv: '',
      amount: this.props.total,
      description: 'Pago de pedido realizado al negocio: '+this.props.accountShop.name
    },
    monthThru: this.month < 10 ? '0'+this.month : this.month,
    yearThru: this.year%100,
    cardHolder: '',
    willPay: false,
    willPayMoney: false,
    willPayTransfer: false,
    willPayPaygate: false,
    validCard: false
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeCard = e => {
    if(e.target.name === 'yearThru') this.setState({ yearThru: e.target.value%100});
    else if(e.target.name === 'monthThru' ) this.setState({ monthThru: e.target.value < 10 ? '0'+e.target.value : e.target.value});
    else if(e.target.name === 'cardHolder') this.setState({ cardHolder: e.target.value});
    else{
      this.setState({
        paymentData: {
          ...this.state.paymentData,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleValidCard = (valid) => {
    this.setState({ validCard: valid });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
  
    try{
      let body = {
        customerName: this.state.form.name,
        customerPhone: this.state.form.mobile_number,
        shippingAddress: this.state.form.shippingAddress ? this.state.form.shippingAddress : null,
        notes: this.state.form.notes ? this.state.form.notes : null,
        shippingTotal: this.props.total,
        items: this.props.cartItems
      };

      // Entra a esta parte si pagara con Efectivo
      if(this.state.willPayMoney) body.paymentGateway = '617872c73900e72af33bcd08'
      // Entra a esta parte si pagara con Transferencia
      if(this.state.willPayTransfer) body.paymentGateway = '617871e1dcb7b02a9f6b754e'

      // Entra a esta parte si pagara con Paygate
      if(this.state.willPayPaygate){
        body.paymentGateway = this.props.accountShop.gateways[0].id // Corregir y buscar el ID de PayGate
        body.paymentData = this.state.paymentData;

        const name = this.state.cardHolder.split(' ');
        let last_name = '';
        name.forEach((value, index) => {
          if(index > 0) last_name += value+" ";
        });
        body.paymentData.firstName = name[0];
        body.paymentData.lastName = last_name;
        body.paymentData.validThru = this.state.monthThru+'/'+this.state.yearThru;

        if(this.state.paymentData.safeIdentifier[0] === '3') body.paymentData.paymentType = 'AMERICAN_EXPRESS';
        if(this.state.paymentData.safeIdentifier[0] === '4') body.paymentData.paymentType = 'VISA';
        if(this.state.paymentData.safeIdentifier[0] === '5') body.paymentData.paymentType = 'MASTERCARD';
        if(this.state.paymentData.safeIdentifier[0] === '6') body.paymentData.paymentType = 'DISCOVER';
      }

      const order = await api.order.create(body);

      if(order.ok){
        let orderInfo = {
          total: order.body.total,
          quantity: this.props.itemsCount,
          shopName: order.body.account.name
        }
        mixpanelApi.orders.orderCreated(orderInfo);
        this.props.emptyCart();
        let whatsappText = '%0AHola%2C+soy+';
        whatsappText += this.state.form.name;
        whatsappText += '%2C%0Ahice+este+pedido+en+'+order.body.account.name+'%3A%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        order.body.items.forEach(element => {
          whatsappText += '- ('+element.quantity+') *'+element.itemSku+' - '+element.itemName+'*';
          if(element.variants){
            whatsappText += ': '
            element.variants.forEach(variant => {
              let [value] = Object.entries(variant);
              whatsappText += value[0]+': '+value[1][0]+', ';
            });
          }
          whatsappText += ' / _'+this.props.currency.symbol+' '+new Intl.NumberFormat('es-HN').format(element.price)+'_%0A';
        });
        whatsappText += '%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        whatsappText += '*TOTAL%3A* _'+this.props.currency.symbol+' '+new Intl.NumberFormat('es-HN').format(order.body.total)+'_';
        whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        if(order.body.shippingAddress) {
          whatsappText += 'Direccion%3A%0A';
          whatsappText += order.body.shippingAddress;
          whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        }
        if(order.body.notes) {
          whatsappText += 'Comentarios%3A%0A';
          whatsappText += order.body.notes;
          whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        }
        whatsappText += 'Mis datos%3A%0A';
        whatsappText += '*Nombre%3A* '+order.body.customerName+'%0A';
        whatsappText += '*Celular%3A* %2B'+order.body.customerPhone;
        whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A%0A';
        whatsappText += '*Pedido No.* '+order.body.code+'%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
        if(this.state.willPayMoney){
          whatsappText += '%0A¡Este pedido sera pagado en efectivo!%0A';
          whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
        }else if(this.state.willPayTransfer){
          whatsappText += '%0A¡Este pedido sera pagado por transferencia bancaria!%0A';
          whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
        }
          //   whatsappText += '%0A¡Este pedido ha sido pagado exitosamente!%0A';
          //   whatsappText += '*Código de autorización%3A* '+order.body.payments[0].gatewayData.transactionID;
          //   whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
          // }
        // if(order.body.payments.length !== 0){
        //   whatsappText += '%0A¡Este pedido ha sido pagado exitosamente!%0A';
        //   whatsappText += '*Código de autorización%3A* '+order.body.payments[0].gatewayData.transactionID;
        //   whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
        // }else {
        //   whatsappText += '%0A*Este pedido todavia no ha sido pagado por el cliente*';
        //   whatsappText += '%0A%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%3D%0A';
        // }

        document.location.href = "https://api.whatsapp.com/send/?phone="+order.body.account.mobileNumber+"&text="+whatsappText+"&app_absent=0";
      }else {
        if(order.body.err && !order.body.err.details){
          this.setState({ error: order.body.err });
          toast.error(order.body.err);
        }else {
          if(order.body.err.details[0].context.key === "customerName"){
            this.setState({ error: "Nombre y Apellido es requerido" });
            toast.error("Nombre y Apellido es requerido", {position: 'top-center', autoClose: 8000});
          }
          if(order.body.err.details[0].context.key === "customerPhone"){
            this.setState({ error: "WhatsApp es requerido" });
            toast.error("WhatsApp es requerido", {position: 'top-center', autoClose: 8000});
          }
          if(order.body.err.details[0].context.key === "shippingTotal"){
            this.setState({ error: "Total es requerido" });
            toast.error("Total es requerido", {position: 'top-center', autoClose: 8000});
          }
          if(order.body.err.details[0].context.key === "items"){
            this.setState({ error: "Tienes que seleccionar productos para realizar un pedido" });
            toast.error("Tienes que seleccionar productos para realizar un pedido", {position: 'top-center', autoClose: 8000});
          }
          if(
            order.body.err.details[0].context.key !== "customerName" &&
            order.body.err.details[0].context.key !== "customerPhone" &&
            order.body.err.details[0].context.key !== "shippingTotal" &&
            order.body.err.details[0].context.key !== "items"
          ){
            this.setState({ error: order.body.err.details[0].message });
            toast.error(order.body.err.details[0].message, {position: 'top-center', autoClose: 8000});
          }
        }
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      toast.error('No se pudo crear tu orden, intenta de nuevo.', {position: 'top-center', autoClose: 8000});
      this.setState({ loading: false, error: "No se puedo crear tu orden, intenta de nuevo." });
    }
  };

  render() {
    return (
      <div className="MainContainer-root">
        <form onSubmit={this.handleSubmit}>
          <div className={this.props.accountShop.gateways.length > 0 ? 'mb-5' : 'mb-6'}>
            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
              <label className="Font-secondary-color">Nombre y Apellido<span className="text-danger">*</span>:</label>
              <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                <input
                  onChange={this.handleChange}
                  className="InputBase-input OutlinedInput-input"
                  type="text"
                  name="name"
                  value={this.state.form.name}
                />
              </div>
            </div>

            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
              <label className="Font-secondary-color">Whatsapp<span className="text-danger">*</span>:</label>
              <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                <PhoneInput
                  country={'hn'}
                  value={this.state.form.mobile_number}
                  onChange={phone => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        mobile_number: phone,
                      },
                    });
                  }}
                  placeholder={'+504 0000 0000'}
                  countryCodeEditable={false}
                  defaultMask={'.... .... .... ..'}
                  enableSearch={true}
                  searchPlaceholder="Buscar"
                  localization={es}
                />
              </div>
            </div>

            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
              <label className="Font-secondary-color">Dirección:</label>
              <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                <textarea
                  onKeyDown={ e => ( e.code === "Digit3" || e.code === "Digit4" || e.code === "Digit5" || e.code === "Digit6" ) && e.preventDefault() }
                  onChange={this.handleChange}
                  className="InputBase-textArea OutlinedInput-input"
                  type="text"
                  name="shippingAddress"
                  value={this.state.form.shippingAddress}
                  rows={5}
                ></textarea>
              </div>
            </div>

            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
              <label className="Font-secondary-color">Comentarios:</label>
              <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                <textarea
                  onKeyDown={ e => ( e.code === "Digit3" || e.code === "Digit4" || e.code === "Digit5" || e.code === "Digit6" ) && e.preventDefault() }
                  onChange={this.handleChange}
                  className="InputBase-textArea OutlinedInput-input"
                  type="text"
                  name="notes"
                  value={this.state.form.notes}
                  rows={3}
                ></textarea>
              </div>
            </div>
          </div>

          {/* Payment Gateways */}
          { this.props.accountShop.gateways.length > 0 && (
            <div style={{marginBottom: '12rem'}}>
              <div className="text-center Font-secondary-color pb-1">
                <h6 className="mb-0">¿Deseas pagar tu pedido ahorita?</h6>
                <ButtonGroup toggle className="my-3">
                  <ToggleButton
                    key={1}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={'no'}
                    checked={!this.state.willPay}
                    onChange={(e) => {
                      this.setState({
                        willPay: false,
                        willPayMoney: false,
                        willPayTransfer: false
                      })
                    }}
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    key={2}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={'yes'}
                    checked={this.state.willPay}
                    onChange={(e) => {
                      this.setState({
                        willPay: true,
                        willPayMoney: false,
                        willPayTransfer: false
                      })
                    }}
                  >
                    Si
                  </ToggleButton>
                </ButtonGroup>
              </div>

              {/* Se muestra para seleccionar el metodo pago */}
              <div className="text-center Font-secondary-color pb-1 mt-4">
                { this.state.willPay && (
                  <>
                    <h6 className="mb-0">Elige tu método de pago:</h6>
                    <ButtonGroup toggle className="my-3">
                      <ToggleButton
                        key={3}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={'money'}
                        checked={this.state.willPayMoney}
                        onChange={(e) => {
                          this.setState({
                            willPayMoney: true,
                            willPayTransfer: false
                          })
                        }}
                      >
                        Efectivo
                      </ToggleButton>
                      <ToggleButton
                        key={4}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={'transfer'}
                        checked={this.state.willPayTransfer}
                        onChange={(e) => {
                          this.setState({
                            willPayTransfer: true,
                            willPayMoney: false,
                          })
                        }}
                      >
                        Transferencia Bancaria
                      </ToggleButton>
                    </ButtonGroup>
                  </>
                )}
              </div>

              { this.state.willPayPaygate && (
                <PayGateForm
                  handleChangeCard={this.handleChangeCard}
                  total={this.props.total}
                  currency={this.props.currency}
                  handleValidCard={this.handleValidCard}
                />
              )}
            </div>
          )}

          <div className="BottomDiv py-3 px-3">
            <h6 className="pb-3 mb-6">Subtotal:</h6>
            <NumberFormat
            typeText={'h6'}
            className={'pb-3 mb-6'}
            number={this.props.total}
            prefix={this.props.currency.symbol} />
          </div>
          
          <button
          disabled={
            !this.state.form.name ||
            !this.state.form.mobile_number ||
            this.state.loading ||
            this.props.itemsCount === 0 ||
            (!this.state.willPayMoney && !this.state.willPayTransfer && this.state.willPay)
          }
          type="submit"
          className="CartButtonBase-root CartButton-root CartButton-contained BottomButton">
            {( !this.state.loading )
              ?
                <FaWhatsapp color={'#FFFFFF'} className="mr-2" />
              : <Spinner animation="border" />
            }
  
            {( !this.state.loading )
              ? 'Pedir por WhatsApp'
              : ''
            }
              
          </button>
        </form>
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);