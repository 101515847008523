import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner'

import PageLoading from '../components/PageLoading';
import ErrorMessage from '../components/ErrorMessage';
import ItemList from '../components/itemList';
import CategoryList from '../components/categoryList';
import api from '../api';

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        categoryList: state.categoryList,
        productList: state.productList,
        itemsCount: state.itemsCount,
        currency: state.currency,
        accountShop: state.accountShop,
        categorySelected: state.categorySelected
    }
}

class Home extends Component {
    state = {
        loading: true,
        loadingProducts: true,
        loadingCategories: true,
        error: null,
        errorCategories: null,
        selected: null,
        companyName: null,
        companyLogo: null
    };
    
    componentDidMount() {
        localStorage.setItem('shopId',this.props.match.params.shopId);
        this.getAccount(this.props.match.params.shopId);
    }

    getAccount = async (accountId) => {
        try{
            this.setState({ error: null });
            const account = await api.account.show(accountId);
            
            if(account.ok){
                this.props.dispatch({
                    type: 'SET_CURRENCY',
                    payload: { currency: account.body.currency }
                });
                this.props.dispatch({
                    type: 'SET_ACCOUNT',
                    payload: { accountShop: account.body }
                });
                this.setState({
                    companyName: account.body.name,
                    companyLogo: account.body.logo
                });

                this.fetchCategories(this.props.categorySelected);
            }else {
                this.setState({ loading: false, loadingCategories: false, error: "Intenta de nuevo" });
            }
        } catch (error) {
            console.log(error);
            this.setState({ loading: false, loadingCategories: false, error: "Intenta de nuevo" });
        }
    }

    fetchCategories = async (id) => {
        try{
            this.setState({ loading: true, loadingCategories: true, error: null });

            let query = '';
            if( (this.props.accountShop.plan.code === '02') && (this.props.accountShop.status === 'inactive') )
                query = '&where={"name":{"startsWith":"Todo"}}';
                
            const categoryList = await api.categories.list(query);
            
            if(categoryList.ok){
                this.props.dispatch({
                    type: 'SET_CATEGORY_LIST',
                    payload: { categoryList: categoryList.body }
                });

                this.fetchData(id ? id : categoryList.body[0].id);
                this.setState({
                    loadingCategories: false,
                    selected: id ? id : categoryList.body[0].id
                });
            }else {
                this.setState({ loading: false, loadingCategories: false, error: "Intenta de nuevo" });
            }
        } catch (error) {
            console.log(error);
            this.setState({ loading: false, loadingCategories: false, error: "Intenta de nuevo" });
        }
    }
      
    fetchData = async (categorySelected) => {
        this.setState({ loadingProducts: true, error: null });

        try{
            let limit = 0;
            if( (this.props.accountShop.plan.code === '02') && (this.props.accountShop.status === 'inactive') )
                limit = 10;

            const productList = await api.items.list(categorySelected, limit);
            
            if(productList.ok){
                this.props.dispatch({
                    type: 'SET_PRODUCT_LIST',
                    payload: { productList: productList.body }
                });
                this.setState({ loading: false, loadingProducts: false });
            }else {
                this.setState({ loading: false, loadingProducts: false, error: "Intenta de nuevo" });
            }
        } catch (error) {
            console.log(error);
            this.setState({ loading: false, loadingProducts: false, error: "Intenta de nuevo" });
        }
    }

    Menu = (list, selected) => {
        return list.map(el => {
            return <CategoryList item={el} key={el.id} selected={selected} />
        });
    }
    
    onSelect = key => {
        this.fetchData(key);
        this.setState({ selected: key });
        this.props.dispatch({
            type: 'SET_SELECTED_CATEGORY',
            id: key
        });
    }
      
    ArrowLeft = <FaAngleLeft className={'arrow-prev'} />;
    ArrowRight = <FaAngleRight className={'arrow-next'} />;

    render() {
        if (this.state.loading === true) {
            return <PageLoading />;
        }else if(this.state.error !== null){
            return <ErrorMessage />
        }else {
            return (
                <React.Fragment>
                    <div className="Home MainContainer-root MainContainer-disableGutters MainContainer">
                        <div className="Header_shop">
                            <div className="Logo">
                                {( this.state.companyLogo != null )
                                    ? <img src={this.state.companyLogo} alt={this.state.companyName} />
                                    : <img src="/logo-default.png" alt="expoceramica-logo" />
                                }
                            </div>
                            <div className="ml-3 py-2" style={{width: '100%', margin: 'auto'}}>
                                <h6 className="mb-0">{this.state.companyName}</h6>
                            </div>
                            <div className="text-right mx-3" style={{margin: 'auto'}}>
                                <Link to={'/'+localStorage.getItem('shopId')+'/cart'} className="CartBadge-root">
                                    <FaShoppingCart size={20} color={'#737373'} />
                                    {(this.props.itemsCount > 0)&& (
                                        <Badge
                                        pill
                                        variant="danger"
                                        className="px-0 CartBadge-badge Badge-anchorTopRight"
                                        style={{fontSize: '0.7rem'}}>
                                            {this.props.itemsCount}
                                        </Badge>
                                    )}
                                </Link>
                            </div>
                        </div>
                        <div className="pt-2">
                            <ScrollMenu
                                data={this.Menu(this.props.categoryList, this.state.selected)}
                                arrowLeft={this.ArrowLeft}
                                arrowRight={this.ArrowRight}
                                selected={this.state.selected}
                                onSelect={this.onSelect}
                                scrollToSelected={true}
                                hideArrows={true}
                                hideSingleArrow={true}
                                disableTabindex={true}
                                translate={0.1}
                                wrapperStyle={{width: '100%'}}
                            />
                        </div>
                        <div className="Grid_wrapper text-center">
                            {( this.state.loadingProducts !== true )
                                ? <ItemList items={this.props.productList} currency={this.props.currency.symbol} />
                                : <div className="py-3">
                                    <Spinner animation="border" />
                                </div>
                            }
                        </div>
                        <div className="pb-5 pt-4 text-center" style={{fontSize: '0.875rem', borderTop: '1px solid #A6A6A6'}}>
                            <img src="/expoceramica-footer-logo.png" alt="expoceramica-logo-footer" style={{maxWidth: '150px'}} className="mb-2" />
                            <p>
                                <a href="https://www.facebook.com/expo.ceramicahn/" target="_blank" rel="noreferrer">Contacta a Expoceramica</a>
                            </p>
                        </div>
                    </div>
                </React.Fragment>

            );
        }
    }
}

export default connect(mapStateToProps)(Home);
