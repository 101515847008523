import React from 'react';
import {
    CardHolder,
    CardNumber,
    CardSecurityCode,
    ValidThruMonth,
    ValidThruYear,
  } from "reactjs-credit-card/form";
  import Card from "reactjs-credit-card/card";
  import { useCardForm } from 'reactjs-credit-card';
  import NumberFormat from '../components/number-format';

function PayGateForm(props) {
    const getFormData = useCardForm();
    const [data, isValid] = getFormData();
    // const [numberValid, setNumberValid] = useState(true);
    
    React.useEffect(() => {
        props.handleValidCard(isValid);
    }, [isValid]);

    function handleSubmit(e) {
        const [data] = getFormData();

        if(e.target.name === 'safeIdentifier' && data.number.isValid){
            e.target.value = data.number.value;
            props.handleChangeCard(e)
        }else if(e.target.name === 'safeIdentifier' && !data.number.isValid){
            props.handleValidCard(false);
        };
        
        if(e.target.name === 'cvv' && data.securityCode.isValid){
            e.target.value = data.securityCode.value;
            props.handleChangeCard(e);
        }else if(e.target.name === 'cvv' && !data.securityCode.isValid){
            props.handleValidCard(false);
        }
    }

    return (
        <div className="Font-secondary-color pb-1">
            <NumberFormat
            label={'Monto'}
            typeText={'h5'}
            className={'my-4 text-center'}
            number={props.total}
            prefix={props.currency.symbol} />

            {/* Card mockup */}
            <Card fixClass="fix-new" cardClass="card-new" />

            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
                {/* <label className="Font-secondary-color">Nombre Completo<span className="text-danger">*</span>:</label> */}
                <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                    <CardHolder
                        placeholder="Nombre en la tarjeta"
                        className={"InputBase-input OutlinedInput-input"}
                        name="cardHolder"
                        onChange={(e) => {
                            props.handleChangeCard(e);
                        }}
                    />
                </div>
            </div>

            <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth">
                {/* <label className="Font-secondary-color">Número de tarjeta<span className="text-danger">*</span>:</label> */}
                <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                    <CardNumber
                        className={`InputBase-input OutlinedInput-input`}
                        placeholder="Número de tarjeta"
                        name="safeIdentifier"
                        onChange={(e) => {
                            handleSubmit(e);
                        }}
                        inputMode='numeric'
                    />
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth pr-2">
                    {/* <label className="Font-secondary-color">CVV<span className="text-danger">*</span>:</label> */}
                    <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl">
                        <CardSecurityCode
                            placeholder="CVV"
                            className="InputBase-input OutlinedInput-input input-text semi"
                            name="cvv"
                            onChange={(e) => {
                                handleSubmit(e);
                            }}
                            inputMode='numeric'
                        />
                    </div>
                </div>

                <div className="FormControl-root FormControl-marginNormal FormControl-fullWidth pl-2">
                    {/* <label className="Font-secondary-color">Fecha de vencimiento<span className="text-danger">*</span>:</label> */}
                    <div className="InputBase-root OutlinedInput-root InputBase-fullWidth MuiInputBase-formControl" style={{boxShadow: 'none'}}>
                        <ValidThruMonth
                            className="InputBase-input OutlinedInput-input ml-1"
                            name="monthThru"
                            onChange={(e) => {
                                props.handleChangeCard(e);
                            }}
                        />
                        <ValidThruYear
                            className="InputBase-input OutlinedInput-input mr-1"
                            name="yearThru"
                            onChange={(e) => {
                                props.handleChangeCard(e);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}



export default PayGateForm;