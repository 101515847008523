import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'

export default class PageLoading extends Component {
  render() {
    return (
      <div className="loader-container">
        <Spinner animation="border" className="loader" />
      </div>
    );
  }
}
