import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../css/ShoppingCart.css';
import { FaAngleLeft, FaPlusCircle, FaWhatsapp } from "react-icons/fa";
import NumberFormat from '../components/number-format';

import PageLoading from '../components/PageLoading';
import CartList from '../components/cartList';
import EmptyCart from '../components/EmptyCart';

// Redux
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
  return{
    cartItems: state.cartItems,
    total: state.total,
    currency: state.currency
  }
}
class ShoppingCart extends Component {
  state = {
    loading: true,
    error: null,
    data: undefined,
  };

  componentDidMount() {}

  render() {
    if (this.state.loading === true && !this.props.cartItems) {
      return <PageLoading />;
    }
    return (
      <div className="Home MainContainer-root MainContainer MainContainer-disableGutters">
        <div className="Page-root Bar-colorTransparent mui-fixed MuiPaper-elevation4">
          <div className="Toolbar-root Toolbar-regular Toolbar-gutters" style={{minHeight: '40px'}}>
            <FaAngleLeft onClick={() => this.props.history.goBack()} size={30} color={'#737373'} />
          </div>
        </div>

        <div className="text-center Font-secondary-color pb-1">
          <h6 className="mb-0">RESUMEN DEL PEDIDO</h6>
        </div>

        {( this.props.cartItems.length !== 0 )
            ? <CartList items={this.props.cartItems} />
            : <EmptyCart />
        }
            
        <div className="pb-5 text-center" style={{marginBottom: '8rem'}}>
          <Link to={'/' + localStorage.getItem('shopId')} style={{display: 'block'}} >
            <FaPlusCircle className="mr-2" />
            <small>
                {( this.props.cartItems.length !== 0 )
                ? 'Agregar más productos'
                : 'Agregar productos'
              }
            </small>
          </Link>
        </div>
        
        <div className="BottomDiv py-3 px-3">
          <h6 className="pb-3 mb-6">Subtotal:</h6>
          <NumberFormat
          typeText={'h6'}
          className={'pb-3 mb-6'}
          number={this.props.total}
          prefix={this.props.currency.symbol} />
        </div>

        <button
        onClick={() => this.props.history.push('/' + localStorage.getItem('shopId') + '/checkout')}
        disabled={this.props.cartItems.length === 0}
        type="button"
        className="CartButtonBase-root CartButton-root CartButton-contained BottomButton">
            <FaWhatsapp color={'#FFFFFF'} className="mr-2" />
            Pedir por WhatsApp
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ShoppingCart)
