import mixpanel from 'mixpanel-browser';

const mixpanelApi = {
    orders: {
        // Esta funcion se ejecuta despues que el usuario crea un pedido exitosamente
        orderCreated(order) {
            mixpanel.track('Create Order', {
                Total: order.total,
                'Item Quantity': order.quantity,
                'Shop Name': order.shopName
            });
            mixpanel.people.set({
            'Last order Created': new Date().toISOString(),
            });
            mixpanel.people.set_once({
            'First order Created': new Date().toISOString(),
            });
        }
    },
}

export default mixpanelApi;