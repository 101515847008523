import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import '../css/ItemShow.css';
import { FaAngleLeft, FaShoppingCart, FaPlusCircle } from "react-icons/fa";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import NumberFormat from '../components/number-format';
import PageLoading from '../components/PageLoading';
import ErrorMessage from '../components/ErrorMessage';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import api from '../api';

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    cartItems: state.cartItems,
    indexCount: state.indexCount,
    itemsCount: state.itemsCount,
    currency: state.currency,
    accountShop: state.accountShop
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (item) => dispatch({
      type: 'ADD_TO_CART',
      item: item
    })
  }
}

class ItemShow extends Component {
  state = {
    loading: true,
    error: null,
    data: null,
    hasVariant: false,
    counter: 0,
    variants: [],
    variantChecked: []
  };

  componentDidMount() {
    if(!localStorage.getItem('shopId')) localStorage.setItem('shopId',this.props.match.params.shopId);
    this.fetchData();
  }

  fetchData = async () => {
    try {
      this.setState({ loading: true, error: null });

      const data = await api.items.show(this.props.match.params.itemId);
      
      if(data.ok){
        let checkedArr = [];
        if(data.body.variants){
          data.body.variants.forEach(element => {
            checkedArr.push({checked: false});
          });
        }else { data.body.variants = [] }
        this.setState({ loading: false, data: data.body, hasVariant: data.body.variants.length === 0 ? false : true, variantChecked: checkedArr });
      }else {
        this.setState({ loading: false, error: "Intenta de nuevo" });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: "Intenta de nuevo" });
    }
  }

  addItemToCart = async (item) => {
    this.setState({ loading: true, error: null });

    try {
      var itemFiltered = {
        idCart: item.id+this.props.indexCount,
        id: item.id,
        itemSKU: item.sku,
        itemName: item.name,
        price: item.price,
        quantity: 1,
        notes: "",
        defaultImage: item.defaultImage
      };
      let itemVariants = [];

      if(this.state.hasVariant){
        this.state.variants.forEach(element => {
          let body = { [element.label] : [element.option] };
          itemVariants.push(body);
        });

        itemFiltered.variants = itemVariants;
      }

      this.props.addToCart(itemFiltered);

      this.setState({ loading: false });
      this.props.history.push('/' + localStorage.getItem('shopId') + '/cart')
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: "No se ha podido agregar el item al pedido" });
    }
  }

  render() {
    var item = this.state.data;
    const zoomOutProperties = {
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      autoplay: false
    };

    if (this.state.loading === true && !this.state.data) {
      return <PageLoading />;
    }else if(this.state.error !== null){
      return <ErrorMessage />;
    }else {
      return (
        <>
          <Helmet>
            <title>{ this.props.accountShop ? `${this.props.accountShop.name} - ${item.name} - Expoceramica` : 'Expoceramica' }</title>
          </Helmet>
          <div className="Home MainContainer-root MainContainer MainContainer-disableGutters">
            <div className="Page-root Bar-positionFixed Bar-colorTransparent mui-fixed MuiPaper-elevation4">
              <div className="Toolbar-root Toolbar-regular NavbarToolbar Toolbar-gutters">
                <button
                onClick={() => this.props.history.goBack()}
                type="button"
                className="btn ButtonBase-root IconButton-root NavbarButton">
                  <FaAngleLeft color={'#FFFFFF'} />
                </button>
                
                <button
                onClick={() => this.props.history.push('/' + localStorage.getItem('shopId') + '/cart')}
                type="button"
                className="btn ButtonBase-root IconButton-root NavbarButton">
                    <FaShoppingCart color={'#FFFFFF'} />
                </button>
              </div>
            </div>

            <div className="slide-container">
              {( item.images.length !== 0 )
                ? <Slide {...zoomOutProperties}>
                    {
                      item.images.map((each, index) => <img key={index} style={{width: "100%"}} src={each.url} alt={each.id} />)
                    }
                  </Slide>
                : <img src="/image-placeholder.png" width="100%" alt="item_image" />
              }
            </div>

            <div className="pt-3 mb-6">
              <div className="MainContainer-root"> 
                <div className="ProductName">
                  <h6 className="m-0">{item.name}</h6>
                </div>
                
                <span style={{display: 'block', fontSize: '0.875rem', marginBottom: '0.35em'}}>
                  SKU: {item.sku}
                </span>
                
                <NumberFormat
                typeText={'h6'}
                number={item.price}
                prefix={this.props.currency.symbol} />
                
                <p className="Font-body2 mt-3 mb-5">{item.description}</p>

                {item.variants.map((element, idx) => {
                  let [value] = Object.entries(element);
                  return (
                    <div key={idx} className="mt-4">
                      <p className="Font-body2 mt-3 mb-1">{value[0]}</p>
                      <ButtonGroup toggle>
                        {value[1].map((val, id) => (
                          <ToggleButton
                            key={id}
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={val}
                            checked={this.state.variantChecked[idx].checked === val}
                            onChange={(e) => {
                              let variantCheck = this.state.variantChecked;
                              variantCheck[idx].checked = e.currentTarget.value;
                              this.setState({ variantChecked: variantCheck });

                              let variantsTemp = this.state.variants;
                              variantsTemp[idx] = { label: value[0], option: e.currentTarget.value };
                              this.setState({ variants: variantsTemp });
                            }}
                          >
                            {val}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>
                  )
                })}
              </div>

              <button
                disabled={this.state.loading || (this.state.variants.length !== item.variants.length && this.state.hasVariant)}
                onClick={() => this.addItemToCart(item)}
                type="button"
                className="CartButtonBase-root CartButton-root CartButton-contained AddToCartButton">
                  <FaPlusCircle color={'#FFFFFF'} className="mr-2" />
                  Agregar al pedido
              </button>
            </div>
          </div>
        </>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemShow);
