const BASE_URL = process.env.REACT_APP_API_URL;

// const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
// const randomNumber = (min = 0, max = 1) =>
//   Math.floor(Math.random() * (max - min + 1)) + min;
// const simulateNetworkLatency = (min = 30, max = 1500) =>
//   delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
  //await simulateNetworkLatency();

  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'shop': localStorage.getItem('shopId'),
  };

  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return { ok: response.ok, status: response.status, body: data };
}

const api = {
  items: {
    list(categoryID, limit) {
      let query = limit === 0 ? '': '&limit=10';
      return callApi(`/item-all?isActive=true&sort=createdAt DESC&categoryId=${categoryID}${query}`);
    },
    create(item) {
      // throw new Error('500: Server error');
      return callApi(`/item`, {
        method: 'POST',
        body: JSON.stringify(item),
      });
    },
    show(itemId) {
      return callApi(`/item-all/${itemId}`);
    },
    update(itemId, updates) {
      return callApi(`/item/${itemId}`, {
        method: 'PUT',
        body: JSON.stringify(updates),
      });
    },
    // Lo hubiera llamado `delete`, pero `delete` es un keyword en JavaScript asi que no es buena idea :P
    destroy(itemId) {
      return callApi(`/item/${itemId}`, {
        method: 'DELETE',
      });
    },
  },
  categories: {
    list(query = '') {
      return callApi(`/item-category-all?isActive=true&sort=createdAt ASC${query}`);
    },
  },
  order: {
    create(order) {
      return callApi(`/order`, {
        method: 'POST',
        body: JSON.stringify(order),
      });
    },
  },
  account: {
    show(accountId) {
      return callApi(`/account-all/${accountId}`);
    },
  }
};

export default api;
