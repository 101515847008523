import React, {useState, useEffect} from 'react';

function format(number) {
  var splitNumber = number.toString().split('.');
  var left = splitNumber[0];
  let right = splitNumber[1];
  var regx = /(\d+)(\d{3})/;
  while (regx.test(left)) {
    left = left.replace(regx, '$1' + ',' + '$2');
  }
  return `${left}.${right}`;
}
function NumberFormat(props) {
  const [number, setNumber] = useState(0);
  useEffect(() => {
    let numberToFormat = props.number.toFixed(2);
    setNumber(format(numberToFormat));
  }, [props.number]);

  return (
    <props.typeText
      className={props.className ? props.className : ''}
      style={{
        // fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        // color: props.color,
        // textAlign: props.textAlign
      }}
    >
      {props.label} {props.label ? ':' : ''} {props.prefix} {number}
    </props.typeText>
  );
}

export default NumberFormat;
