import React from "react";
import { useHistory } from "react-router";
import useGaTracker from "../useGaTracker";
import { Helmet } from "react-helmet";
import { ToastContainer } from 'react-toastify';

// Redux
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        accountShop: state.accountShop
    }
}

const Layout = (props) => {
    const history = useHistory();
    const [changeTitle, setchangeTitle] = React.useState(false);
    const [labelTitle, setlabelTitle] = React.useState(null);
    useGaTracker();

    React.useEffect(() => {
        return history.listen((location) => { 
            let url_split = location.pathname.split('/');
            if(url_split.includes("cart")){
               setlabelTitle('Resumen del pedido');
               setchangeTitle(true);
            }else if(url_split.includes("checkout")){
                setlabelTitle('Checkout');
                setchangeTitle(true);
            }else {
                setlabelTitle(null);
                setchangeTitle(false);
            }
        }) 
     },[history]);

    React.useEffect(() => {
        let url_split = history.location.pathname.split('/');
        if(url_split.includes("cart")){
            setlabelTitle('Resumen del pedido');
            setchangeTitle(true);
        }else if(url_split.includes("checkout")){
            setlabelTitle('Checkout');
            setchangeTitle(true);
        }
    },[]) 
    
    return (
        <>
            <ToastContainer /> 
            <Helmet>
                { changeTitle
                    ? <title>{ props.accountShop ? `${props.accountShop.name} - ${labelTitle} - Expoceramica` : 'Expoceramica' }</title>
                    : <title>{ props.accountShop ? `${props.accountShop.name} - Expoceramica` : 'Expoceramica' }</title>
                }
                <meta name="description" content={props.accountShop ? props.accountShop.description : 'Tu negocio online conectado a WhatsApp.'}></meta>
            </Helmet>
            
            {props.children}
        </>
    );
}

export default connect(mapStateToProps)(Layout);