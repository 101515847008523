import React from 'react';
import { FaExclamationTriangle } from "react-icons/fa";

function ErrorMessage() {
  return (
    <div className="Home MainContainer-root MainContainer-disableGutters MainContainer text-center">
      <div className="py-5">
        <h1><FaExclamationTriangle /></h1>
        <h3>!Oops, algo salió mal!</h3>
        <p>Recarga la pagina, y si aún no funciona, ten la seguridad de que estamos al tanto y estamos trabajando para solucionarlo.</p>
      </div>
    </div>
  );
}

export default ErrorMessage;
