import React from 'react';
import CartListItem from './cartListItem';

function CartList(props) {
  return (
    <div className="MainContainer-root Cart_cardList">
      {props.items.map((item, index) => {
        return (
          <div key={item.id+index}>
            <CartListItem item={item} />
          </div>
        );
      })}
    </div>
  );
}



export default CartList;