import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import NumberFormat from './number-format';

class ItemListItem extends React.Component {
    render() {
      return (
        <div className="GridItem_card">
          <div className="GridItem_image">
          {( this.props.item.defaultImage )
            ? <img src={this.props.item.defaultImage} className="GridImg" loading="lazy" alt={this.props.item.name} />
            : <img src="/image-placeholder.png" className="GridImg" loading="lazy" alt="item_image" />
          }
          </div>
          <span className="Font-root GridItem_caption Font-overline Font-displayBlock">
            <small>SKU: {this.props.item.sku}</small><br/>
            {this.props.item.name}
          </span>
          <div className="GridItem_priceContainer">
            <NumberFormat
            typeText={'span'}
            className="Font-root GridItem_price Font-caption"
            number={this.props.item.price}
            prefix={this.props.currency} />
            {/* <span className="Font-root GridItem_price Font-caption">L. {this.props.item.price}</span> */}
          </div>
        </div>
      );
    }
}

function ItemList(props) {
  let match = useRouteMatch();
  return (
    <div className="Grid_list">
      {props.items.map(item => {
        return (
          <div key={item.id} className="Font-root Link-root Link-underlineHover GridItem_item Font-colorPrimary">
            <Link
              className="text-reset text-decoration-none"
              to={`${match.url}/item/${item.id}`}
            >
              <ItemListItem item={item} currency={props.currency} />
            </Link>
          </div>
        );
      })}
    </div>
  );

}

 export default ItemList;