import React, { Component } from 'react';
import { FaAngleLeft } from "react-icons/fa";
import CheckoutForm from '../components/checkoutForm';

class Checkout extends Component {
  handleClick = e => {
    console.log('Button was clicked');
  };


  render() {
    return (
      <div className="Home MainContainer-root MainContainer MainContainer-disableGutters">
        <div className="Page-root Bar-colorTransparent mui-fixed MuiPaper-elevation4">
          <div className="Toolbar-root Toolbar-regular Toolbar-gutters" style={{minHeight: '40px'}}>
            <FaAngleLeft onClick={() => this.props.history.goBack()} size={30} color={'#737373'} />
          </div>
          <div className="text-center Font-secondary-color pb-1">
            <h6 className="mb-0">TUS DATOS</h6>
          </div>
        </div>
        
        <CheckoutForm history={this.props.history} />
      </div>
    );
  }
}

export default Checkout;
